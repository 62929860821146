<template>
  <div class="page">
    <div class="home">
      <img
        src="../assets/logo-blue-sect.svg"
        alt="blured logo"
        class="blurLogo"
      />
      <h1>Politique de confidentialité</h1>
      <h2>1. Introduction</h2>
      <p>
        Timesheet App (« <span>nous</span> », « <span>notre</span> », «
        <span>nos</span> ») s'engage à <span>protéger</span> et à
        <span>respecter</span> la <span>confidentialité</span> de ses
        <span>utilisateurs</span>. Cette
        <span>politique de confidentialité</span> explique les types d'<span
          >informations personnelles</span
        >
        que nous collectons auprès des <span>utilisateurs</span> de Timesheet
        App et comment nous <span>utilisons</span>, <span>stockons</span> et
        <span>protégeons</span> ces informations.
      </p>
      <h2>2. Collecte des informations</h2>
      <p>
        Les <span>informations personnelles</span> que nous collectons auprès
        des <span>utilisateurs</span> de Timesheet App incluent l'<span
          >adresse e-mail</span
        >
        et les <span>rapports de crash</span> de l'application. Ces informations
        sont collectées dans le but de fournir des services d'<span
          >authentification</span
        >
        et d'<span>amélioration</span> de l'application.
      </p>
      <h2>3. Utilisation des informations</h2>
      <p>
        Nous utilisons les <span>informations personnelles</span> collectées
        pour permettre aux <span>utilisateurs</span> de s'<span
          >authentifier</span
        >
        et d'<span>accéder</span> à notre service Timesheet. Les
        <span>rapports de crash</span> sont utilisés pour
        <span>identifier</span> et <span>résoudre</span> les problèmes
        techniques liés à l'application.
      </p>
      <h2>4. Partage des informations</h2>
      <p>
        Nous ne <span>partageons</span> pas les
        <span>informations personnelles</span> collectées avec des
        <span>tiers</span>.
      </p>
      <h2>5. Services et outils tiers</h2>
      <p>
        Nous utilisons <span>Firebase</span> et
        <span>Google Analytics</span> pour collecter, stocker et traiter les
        données des <span>utilisateurs</span>. Ces outils sont utilisés à des
        fins de <span>rapport de crashes</span> et
        d'<span>authentification</span>.
      </p>
      <h2>6. Sécurité des données</h2>
      <p>
        Nous prenons la <span>sécurité</span> des
        <span>données personnelles</span> très au sérieux et avons mis en place
        des <span>mesures de sécurité</span> pour protéger les informations des
        <span>utilisateurs</span>, y compris le <span>cryptage</span> des
        données avant qu'elles ne sortent de l'appareil de
        l'<span>utilisateur</span>.
      </p>
      <h2>
        <span
          >7. Accès, modification et suppression des informations
          personnelles</span
        >
      </h2>
      <p>
        Les <span>utilisateurs</span> peuvent <span>accéder</span>,
        <span>modifier</span> ou <span>supprimer</span> leurs
        <span>informations personnelles</span> en nous contactant à
        info@guyennetechnologies.com.
      </p>
      <h2>8. Conformité</h2>
      <p>
        Notre application est conforme au
        <span>Règlement général sur la protection des données (RGPD)</span> de
        l'<span>Union européenne</span> et à la
        <span>loi sur la protection des données personnelles</span> dans
        d'autres <span>juridictions pertinentes</span>.
      </p>
      <h2>9. Modifications de la politique de confidentialité</h2>
      <p>
        Nous nous réservons le droit de <span>modifier</span> cette
        <span>politique de confidentialité</span> à tout moment. Les
        <span>modifications</span> entreront en vigueur immédiatement après leur
        publication sur notre <span>application</span>.
      </p>
      <h2>10. Contact</h2>
      <p>
        Si vous avez des <span>questions</span> concernant notre
        <span>politique de confidentialité</span>, veuillez nous
        <span>contacter</span> à
        <a href="mailto:info@guyennetechnologies.com"
          >info@guyennetechnologies.com</a
        >.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // data
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../variables.scss";
.home {
  //   height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  //   height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 150px;
  h1 {
    font-weight: 900;
    color: $blue;
    margin-bottom: 0px;
    margin-top: 0px;
    text-transform: uppercase;
    line-height: 1.2;
    letter-spacing: 0.1em;
    text-align: center;
    margin-bottom: 60px;
  }
  p {
    font-weight: 500;
    color: $blue;
    margin-bottom: 0px;
    margin-top: 0px;
    line-height: 1.2;
    letter-spacing: 0.1em;
    text-align: left;
    font-size: 16px;
    margin-bottom: 40px;
    span {
      font-weight: 900;
    }
  }
  h2 {
    font-weight: 900;
    color: $blue;
    margin-bottom: 0px;
    margin-top: 0px;
    text-transform: uppercase;
    line-height: 1.2;
    letter-spacing: 0.1em;
    font-size: 20px;
    margin-bottom: 10px;
    text-align: left;
  }
  &.two {
    align-items: center;
    justify-content: center;
    p.subtitle {
      opacity: 1;
      font-size: 19px;
      text-align: left;
      color: $blue;
      font-weight: 500;
      max-width: 600px;
      word-wrap: break-word;
      margin-bottom: 20px;
      transform: translateY(0px);
      transition: all 300ms;
      transition-delay: 100ms;
      transition-timing-function: cubic-bezier(0.78, 0.01, 0.26, 0.99);
      span {
        font-weight: 900;
      }
      &.input-focused {
        transform: translateY(-20px);
      }
      &.loading {
        opacity: 0;
      }
    }
    input.email {
      width: 600px;
      text-align: center;
      z-index: 999;
      padding: 30px;
      font-size: 22px;
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.6);
      color: $blue;
      text-transform: uppercase;
      font-family: tussilago, sans-serif;
      font-weight: 900;
      box-shadow: 0px 14px 59px rgba(5, 0, 226, 0.35);
      transform: translateY(0px);
      transition: all 300ms;
      transition-timing-function: cubic-bezier(0.78, 0.01, 0.26, 0.99);
      &::placeholder {
        color: rgba(5, 0, 226, 0.15);
      }
      &.notEmpty {
        background-color: rgba(255, 255, 255, 1);
        &.loading {
          opacity: 0;
        }
      }
      &:focus-visible {
        box-shadow: 0px 34px 59px rgba(5, 0, 226, 0.15);
        background-color: rgba(255, 255, 255, 1);
        transform: translateY(-20px);
      }
    }
  }
  .blurLogo {
    opacity: 0.4;
    position: fixed;
    top: 0px;
    right: 0px;
    height: 70vh;
    filter: blur(40px);
  }
}

// is mobile
@media (max-width: 768px) {
  .home {
    padding: 50px;
    h1 {
      font-size: 20px;
      margin-bottom: 30px;
    }
    p {
      font-size: 16px;
      margin-bottom: 40px;
    }
    h2 {
      font-size: 16px;
      margin-bottom: 10px;
    }
    &.two {
      p.subtitle {
        font-size: 16px;
        margin-bottom: 20px;
      }
      input.email {
        width: 100%;
        padding: 20px;
        font-size: 16px;
      }
    }
  }
}
</style>
